<template>
  <div class="Reviewrefund">
    <app-header :title="title" :isShowBack="true"></app-header>
    <div class="app-detail__subTitle">
      确认退款金额（
      <span v-if="bizTypeStatus">
        <span v-show="LeaseStatus">长租</span>
        <span v-show="!LeaseStatus">短租</span>
      </span>
      <span v-else>取消订单</span>
      ）
    </div>
    <div class="ul" v-if="bizTypeStatus">
      <div class="ul_li">
        <div class="ul_li_one">入住日期：</div>
        <div class="ul_li_scond" v-text="optionList.rentStartDate"></div>
        <div class="ul_li_thrwe">房客的入住起始日期</div>
      </div>
      <div class="ul_li">
        <div class="ul_li_one">退房日期：</div>
        <div class="ul_li_scond" v-text="optionList.quitDate"></div>
        <div class="ul_li_thrwe">房客选择的退房日期</div>
      </div>
      <!-- 长租模块 -->
      <div v-show="LeaseStatus">
        <div class="ul_li">
          <div class="ul_li_one">租金应退（元）：</div>
          <div class="ul_li_scond" v-text="optionList.rentFee"></div>
          <div class="ul_li_thrwe">租金应退=租金应退天数*每日租金费用</div>
        </div>
        <div class="ul_li">
          <div class="ul_li_one">物业费应退（元）：</div>
          <div class="ul_li_scond" v-text="optionList.checkoutPropertyFee"></div>
          <div class="ul_li_thrwe">物业费应退=物业费应退天数*每日物业费</div>
        </div>
        <div class="ul_li">
          <div class="ul_li_one">押金应退（元）：</div>
          <div class="ul_li_scond" v-text="optionList.deposit"></div>
          <div class="ul_li_thrwe">押金计算：长租，首期合同约定期之前退房不退还押金，合同期期满后退房全额退还押金</div>
        </div>
        <!-- <div class="ul_li">
        <div class="ul_li_one">租金应缴天数：</div>
        <div class="ul_li_scond">20</div>
        <div class="ul_li_thrwe">租金应缴金额=应缴天数*每日租金</div>
      </div>
      <div class="ul_li">
        <div class="ul_li_one">物业费应缴天数：</div>
        <div class="ul_li_scond">20</div>
        <div class="ul_li_thrwe">物业费应缴金额=物业费应缴天数*每日物业费</div>
        </div>-->
        <div class="ul_li">
          <div class="ul_li_one">租金应退天数：</div>
          <div class="ul_li_scond" v-text="optionList.ShouldrentDate"></div>
          <div class="ul_li_thrwe">根据签订的合同期限与房客申请的退房日期计算</div>
        </div>
        <div class="ul_li">
          <div class="ul_li_one">物业费应退天数：</div>
          <div class="ul_li_scond" v-text="optionList.ShouldPropertycostsDate"></div>
          <div class="ul_li_thrwe">根据签订的合同期限与房客申请的退房日期计算</div>
        </div>
        <!-- <div class="ul_li">
        <div class="ul_li_one">合计应缴：</div>
        <div class="ul_li_scond">0</div>
        <div class="ul_li_thrwe">合计应缴=租金应缴金额+物业费应缴金额</div>
        </div>-->
      </div>
      <!-- 短租模块 -->
      <div v-show="!LeaseStatus">
        <div class="ul_li">
          <div class="ul_li_one">租金应退金额（元）：</div>
          <div class="ul_li_scond" v-text="optionList.shortcheckoutDepositFee"></div>
          <div class="ul_li_thrwe">
            租金应退金额=租金应退天数*每日对应的租金；
            <span style="color: #999999;">入住第一天18:00前退房，则第一天租金扣除50%</span>
          </div>
        </div>
        <div class="ul_li">
          <div class="ul_li_one">押金应退（元）：</div>
          <div class="ul_li_scond" v-text="optionList.shortdeposit"></div>
          <div class="ul_li_thrwe">押金计算：退租系统一次性退还押金总金额</div>
        </div>
        <div class="ul_li">
          <div class="ul_li_one">租金应退天数：</div>
          <div class="ul_li_scond" v-text="optionList.shortdepositData"></div>
          <div class="ul_li_thrwe">根据订单起始日期和房客申请的退房日期核算</div>
        </div>
      </div>
      <div class="ul_li">
        <div class="ul_li_one">合计应退（元）：</div>
        <div class="ul_li_scond" v-text="optionList.Totalrefunded"></div>
        <div class="ul_li_thrwe" v-show="!LeaseStatus">
          <div>合计应退=租金应退金额+押金应退金额</div>
        </div>
        <div class="ul_li_thrwe" v-show="LeaseStatus">
          <div>情形1：合计应退=租金应退金额+押金应退金额+物业费应退金额</div>
          <div>情形2：合计应退=押金应退金额-租金应缴金额-物业费应缴金额</div>
        </div>
      </div>
      <div class="ul_li">
        <div class="ul_li_one">房东扣款项（元）：</div>
        <div class="ul_li_scond" v-text="optionList.deductionFee"></div>
        <div class="ul_li_thrwe">
          <div>房东自定义的扣款项，扣款项必须小于或等于合计应退金额</div>
          <div>
            房东扣款凭证：
            <a href="#" @click="LookFun()">查看</a>
          </div>
          <div>
            房东扣款原因：
            <span v-text="formInline.textarea"></span>
          </div>
        </div>
      </div>
      <div class="ul_li">
        <div class="ul_li_one">实际应退（元）：</div>
        <div class="ul_li_scond" v-text="optionList.checkoutSum"></div>
        <div class="ul_li_thrwe">实际应退=合计应退-房东扣款项</div>
      </div>
    </div>

    <div class="ul" v-else>
      <div class="ul_li">
        <div class="ul_li_one">入住日期：</div>
        <div class="ul_li_scond" v-text="cancelorder.rentStartDate"></div>
        <div class="ul_li_thrwe">房客的入住起始日期</div>
      </div>
      <div class="ul_li">
        <div class="ul_li_one">退房日期：</div>
        <div class="ul_li_scond" v-text="cancelorder.quitDate"></div>
        <div class="ul_li_thrwe">房客选择的退房日期</div>
      </div>
      <div class="ul_li">
        <div class="ul_li_one">租金应退金额（元）：</div>
        <div class="ul_li_scond" v-text="cancelorder.checkoutRentalFee"></div>
        <div class="ul_li_thrwe">取消订单属于未入住，系统全额退还租金、押金</div>
      </div>
      <div class="ul_li">
        <div class="ul_li_one">押金应退（元）：</div>
        <div class="ul_li_scond" v-text="cancelorder.checkoutDepositFee"></div>
        <div class="ul_li_thrwe">取消订单属于未入住，系统全额退还租金、押金</div>
      </div>
      <div class="ul_li">
        <div class="ul_li_one">订单总金额（元）：</div>
        <div class="ul_li_scond" v-text="cancelorder.orderall"></div>
        <div class="ul_li_thrwe">订单总金额=订单总租金+押金，订单支付总金额</div>
      </div>
      <div class="ul_li">
        <div class="ul_li_one">取消订单时间：</div>
        <div class="ul_li_scond" v-text="cancelorder.cancelDate"></div>
      </div>
      <div class="ul_li">
        <div class="ul_li_one">应退总金额（元）：</div>
        <div class="ul_li_scond" v-text="cancelorder.Totalamount"></div>
        <div class="ul_li_thrwe">应退总金额=订单总金额</div>
      </div>
    </div>

    <div class="app-detail__subTitle">验证短信验证码</div>
    <div class="newsDiv">
      <div>
        <span>
          短信验证码已发送至
          <!-- <span>158</span> *******
          <span>48</span>-->
          <span>{{phoneNumber}}</span>
          的手机号码
        </span>
      </div>
      <div v-show="!showTite">
        <span style="color: #0099ff;">({{count}})</span>秒
      </div>
      <div>
        <el-input
          style="width:160px"
          size="medium"
          v-model="inputnum"
          @input="inputFun"
          placeholder="请输入验证码"
        ></el-input>
        <el-button class="news_span" @click="send" :disabled="!show">
          <span>获取验证码</span>
        </el-button>
      </div>
      <div>
        <span>
          房客：
          <span style="font-weight: 700;" v-text="Namelist"></span>
        </span>
        <span style="margin-left:20px">
          总退款：
          <span style="font-weight: 700;">
            <span v-text="Namepice"></span>元
          </span>
        </span>
      </div>
      <div>
        <el-button
          id="RefundReviewbut"
          type="info"
          @click="RefundReview()"
          :disabled="buttonState"
        >确认退款</el-button>
      </div>
    </div>
    <el-dialog title="房东扣款凭证" :visible.sync="dialogVisible" width="640px" :close-on-click-modal="false">
      <div class="alertTilte">
        <div class="ul_div">
          <div class="ul_li_one">房客姓名：</div>
          <div class="ul_li_thrwe">
            <el-input :disabled="true" v-model="formInline.name"></el-input>
          </div>
        </div>
        <div class="ul_div">
          <div class="ul_li_one">房源：</div>
          <div class="ul_li_thrwe">
            <el-input :disabled="true" v-model="formInline.address"></el-input>
          </div>
        </div>
        <div class="ul_div">
          <div class="ul_li_one">退房日期：</div>
          <div class="ul_li_thrwe">
            <el-input :disabled="true" v-model="formInline.quitDate"></el-input>
          </div>
        </div>
        <div class="ul_div">
          <div class="ul_li_one">应退金额（元）：</div>
          <div class="ul_li_thrwe">
            <el-input :disabled="true" v-model="formInline.RentalFee"></el-input>
          </div>
        </div>
        <div class="ul_div">
          <div class="ul_li_one">是否有扣款：</div>
          <div class="ul_li_thrwe">
            <el-select v-model="formInline.checkOut" :disabled="true">
              <el-option label="否" value="0"></el-option>
              <el-option label="是" value="1"></el-option>
            </el-select>
          </div>
        </div>
        <div v-show="hiddenState">
          <div class="ul_div">
            <div class="ul_li_one">扣款金额（元）：</div>
            <div class="ul_li_thrwe">
              <el-input :disabled="true" v-model="formInline.DeductionFun"></el-input>
            </div>
          </div>
          <div class="ul_div">
            <div class="ul_li_one">扣款原因：</div>
            <div class="ul_li_thrwe">
              <textarea
                class="textarea"
                v-model="formInline.textarea"
                name
                id
                :disabled="true"
                cols="30"
                rows="10"
              ></textarea>
            </div>
          </div>
          <div class="ul_div">
            <div class="ul_li_one">上传凭证：</div>
            <div class="ul_li_thrwe clearfix">
              <div class="img_div" v-for="item in urlList" :key="item">
                <img :src="item" alt @click="clickImg(item)" />
              </div>
            </div>
          </div>
        </div>
        <div style="text-align: center;">
          <el-button type="info" @click="openUP()">关闭</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="图片预览" :visible.sync="dialogVisibleimg" :close-on-click-modal="false">
      <img style="width: 100%;height: 100%;" :src="lookImg" alt />
    </el-dialog>

    <Verify @success="verifiedBy" :mode="'pop'" :captchaType="'blockPuzzle'" :imgSize="{ width: '330px', height: '155px' }"
      ref="verify1"></Verify>
  </div>
</template>
<script>
const TIME_COUNT = 60; //更改倒计时时间
export default {
  data() {
    return {
      title: "审核退款",
      rentalStyle: "",
      optionList: {
        rentStartDate: "",
        quitDate: "",
        rentFee: "",
        checkoutPropertyFee: "",
        deposit: "",
        // rentDate: "",
        // PropertycostsDate: "",
        ShouldrentDate: "",
        ShouldPropertycostsDate: "",
        // rentStartDate: "",
        //短租
        shortcheckoutDepositFee: "",
        shortdeposit: "",
        shortdepositData: "",

        Totalrefunded: "",
        deductionFee: "",
        checkoutSum: ""
      },
      bizTypeStatus: false,
      cancelorder: {
        rentStartDate: "",
        quitDate: "",
        checkoutRentalFee: "",
        checkoutDepositFee: "",
        orderall: "",
        cancelDate: "",
        Totalamount: ""
      },
      show: true, // 初始启用按钮
      showTite: true, // 初始启用按钮
      count: "", // 初始化次数
      timer: null,
      LeaseStatus: false,
      dialogVisible: false,
      dialogVisibleimg: false,
      lookImg: "",
      imgSrc: "",
      imgCount: 2,
      inputnum: "",
      refundOperationId: "",
      //   accountfrist: "",
      //   accountlast: "",
      Namelist: "",
      Namepice: "",
      formInline: {
        name: "",
        address: "",
        quitDate: "",
        RentalFee: "",
        checkOut: "",
        DeductionFun: "",
        textarea: ""
      },
      urlList: [],
      phoneNumber: "",
      buttonState: false,
      hiddenState: false
    };
  },
  activated() {
    this.phoneNumber = this.cache.getLS("userInfo").phoneNum;

    let id = this.$route.params.id || "";
    this.rentalStyle = this.$route.query.rentalStyle || "";
    if (this.rentalStyle == "0") {
      this.LeaseStatus = true;
    } else {
      this.LeaseStatus = false;
    }
    this.bizType = this.$route.query.bizType || "";
    if (this.bizType == "0" || this.bizType == "1") {
      this.bizTypeStatus = true;
    } else {
      this.bizTypeStatus = false;
    }
    this.GetdataList(id);
    this.buttonState = false;
    this.show = true;
    this.showTite = true;
    this.inputnum = "";
  },
  created() {
    let id = this.$route.params.id || "";
    this.GetdataList(id);
  },
  methods: {
    GetdataList(id) {
      let dto = {
        id: id
      };
      this.post("finance-service/refundOperation/detail", dto).then(data => {
        console.log(data);
        this.optionList.rentStartDate = data.rentStartDate;
        this.optionList.quitDate = data.quitDate;
        this.optionList.rentFee =
          data.checkoutRentalFee == null
            ? "0.00"
            : parseFloat(data.checkoutRentalFee).toFixed(2);
        this.optionList.checkoutPropertyFee =
          data.checkoutPropertyFee == null
            ? "0.00"
            : parseFloat(data.checkoutPropertyFee).toFixed(2);
        this.optionList.deposit = data.checkoutDepositFee = null
          ? "0.00"
          : parseFloat(data.checkoutDepositFee).toFixed(2);

        this.optionList.ShouldrentDate = data.quitDayCount; //计算
        this.optionList.ShouldPropertycostsDate = data.quitDayCount;

        this.optionList.shortcheckoutDepositFee = parseFloat(
          data.checkoutRentalFee
        ).toFixed(2);
        this.optionList.shortdeposit = parseFloat(
          data.checkoutDepositFee
        ).toFixed(2);

        this.optionList.shortdepositData = data.quitDayCount; //计算

        this.optionList.Totalrefunded = (
          parseFloat(data.checkoutSum) +
          parseFloat(data.deductionFee == null ? "0.00" : data.deductionFee)
        ).toFixed(2);
        this.optionList.deductionFee =
          data.deductionFee == null
            ? "0.00"
            : parseFloat(data.deductionFee).toFixed(2);
        this.optionList.checkoutSum =
          data.checkoutSum == null
            ? "0.00"
            : parseFloat(data.checkoutSum).toFixed(2);

        this.cancelorder.rentStartDate = data.rentStartDate;
        this.cancelorder.quitDate = data.quitDate;
        this.cancelorder.checkoutRentalFee =
          data.checkoutRentalFee == null
            ? "0.00"
            : parseFloat(data.checkoutRentalFee).toFixed(2);
        this.cancelorder.checkoutDepositFee =
          data.checkoutDepositFee == null
            ? "0.00"
            : parseFloat(data.checkoutDepositFee).toFixed(2);
        this.cancelorder.orderall = (
          parseFloat(data.checkoutRentalFee) +
          parseFloat(
            data.checkoutDepositFee == null ? "0.00" : data.checkoutDepositFee
          )
        ).toFixed(2);
        this.cancelorder.cancelDate = data.landlordAgreeQuitDate;
        this.cancelorder.Totalamount =
          data.checkoutSum == null ? "0.00" : data.checkoutSum;

        this.formInline.name = data.tenantRealname;
        this.formInline.address = data.address;
        this.formInline.quitDate = data.quitDate;
        this.formInline.RentalFee = (
          parseFloat(data.checkoutSum) +
          parseFloat(data.deductionFee == null ? "0.00" : data.deductionFee)
        ).toFixed(2);
        this.formInline.checkOut = data.deductionFee == null ? "0" : "1";
        this.formInline.DeductionFun =
          data.deductionFee == null
            ? "0.00"
            : parseFloat(data.deductionFee).toFixed(2);
        this.formInline.textarea =
          data.deductionDesc == null ? "无" : data.deductionDesc;
        this.urlList = data.deductionPic;
        this.Namelist = data.tenantRealname;
        this.Namepice =
          data.checkoutSum == null
            ? "0.00"
            : parseFloat(data.checkoutSum).toFixed(2);
        this.refundOperationId = data.id;
        if (
          (data.deductionDesc == "" &&
            data.deductionFee == "" &&
            data.deductionPic == "") ||
          (data.deductionDesc == null &&
            data.deductionFee == null &&
            data.deductionPic == null)
        ) {
          this.hiddenState = false;
        } else {
          this.hiddenState = true;
        }
      });
    },
    verifiedBy(params) {
      this.onSend(params.captchaVerification);
    },
    onSend(captchaVerification) {
      let dto = {
        mobile: this.phoneNumber,
        captchaVerification: captchaVerification,
      };
      if (!this.timer) {
        this.post("user-service/weChat/sendMobileCode", dto).then(res => {
          console.log(res);
          this.count = TIME_COUNT;
          this.show = false;
          this.showTite = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.showTite = true;
              this.show = true;
              clearInterval(this.timer); // 清除定时器
              this.timer = null;
            }
          }, 1000);
          this.smsCode = res;
        });
      }
    },
    send() {
      this.$refs.verify1.refresh();
      this.$refs.verify1.show();
    },
    RefundReview() {
      if (this.inputnum) {
        var dto = {
          refundOperationId: this.refundOperationId,
          smsCode: this.inputnum
        };
        this.post("finance-service/refundOperation/start", dto)
          .then(res => {
            this.$message({
              message: "退款审核成功!",
              type: "success"
            });
            this.buttonState = true;
            this.show = false;
            this.$back();
          })
          .catch(res => {
            this.buttonState = false;
            this.show = true;
          });
      }
    },
    currentChange(idx) {
      this.imgSrc = this.imgList[idx - 1];
    },
    LookFun() {
      this.dialogVisible = true;
    },
    openUP() {
      this.dialogVisible = false;
    },
    clickImg(item) {
      this.lookImg = item;
      this.dialogVisibleimg = true;
    },
    inputFun(e) {
      if (e) {
        document
          .getElementById("RefundReviewbut")
          .setAttribute(
            "style",
            "background-color:#30BAC1;border-color: #30BAC1"
          );
      } else {
        document
          .getElementById("RefundReviewbut")
          .setAttribute("style", "background-color:#909399");
      }
    }
  }
};
</script>
<style lang="scss">
.Reviewrefund {
  .app-detail__subTitle {
    padding: 5px;
    margin-bottom: 20px;
    font-size: 15px;
    background-color: #ecf5ff;
    color: #666;
  }
  .ul {
    .ul_li {
      display: flex;
      line-height: 30px;
      font-family: "微软雅黑";
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      color: #666666;
      margin-bottom: 10px;
    }
    .ul_li_one {
      width: 200px;
      text-align: right;
    }
    .ul_li_scond {
      width: 160px;
      text-align: center;
    }
    .ul_li_thrwe {
      width: auto;
      text-align: left;
    }
  }
  .newsDiv {
    line-height: 30px;
    font-family: "微软雅黑";
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: #666666;
    padding-left: 200px;
    padding-bottom: 20px;
    div {
      margin-bottom: 10px;
    }
    .news_span {
      height: 36px;
      padding: 0 10px;
      background-color: #fff;
      margin-left: 10px;
      border: none;
      outline: none;
      cursor: pointer;
      color: #0099ff;
    }
  }
  .alertTilte {
    .ul_div {
      display: flex;
      line-height: 40px;
      font-family: "微软雅黑";
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      color: #666666;
      margin-bottom: 10px;
    }
    .ul_li_one {
      width: 200px;
      height: 40px;
      text-align: right;
    }
    .ul_li_scond {
      width: 160px;
      height: 40px;
      text-align: center;
    }
    .ul_li_thrwe {
      width: 310px;
      text-align: left;
      .img_div {
        width: 70px;
        height: 70px;
        float: left;
        border: 1px solid #dcdfe6;
        margin-right: 5px;
        margin-bottom: 5px;
        img {
          width: 100%;
          height: 100%;
          vertical-align: top;
        }
      }
    }
    .textarea {
      width: 304px;
      height: 80px;
      border-color: #dcdfe6;
      color: #303133;
      resize: none;
    }
  }
}
</style>